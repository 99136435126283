exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investments-js": () => import("./../../../src/pages/investments.js" /* webpackChunkName: "component---src-pages-investments-js" */),
  "component---src-pages-jak-to-dziala-js": () => import("./../../../src/pages/jak-to-dziala.js" /* webpackChunkName: "component---src-pages-jak-to-dziala-js" */),
  "component---src-pages-kariera-js": () => import("./../../../src/pages/kariera.js" /* webpackChunkName: "component---src-pages-kariera-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-porownaj-js": () => import("./../../../src/pages/porownaj.js" /* webpackChunkName: "component---src-pages-porownaj-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-schowek-js": () => import("./../../../src/pages/schowek.js" /* webpackChunkName: "component---src-pages-schowek-js" */),
  "component---src-pages-thank-you-page-js": () => import("./../../../src/pages/thank-you-page.js" /* webpackChunkName: "component---src-pages-thank-you-page-js" */),
  "component---src-pages-uslugi-operatorskie-js": () => import("./../../../src/pages/uslugi-operatorskie.js" /* webpackChunkName: "component---src-pages-uslugi-operatorskie-js" */),
  "component---src-templates-investment-js": () => import("./../../../src/templates/investment.js" /* webpackChunkName: "component---src-templates-investment-js" */)
}

